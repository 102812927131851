import { Consignment } from '../Types/Consignment';
import { apiUrl } from '../config/apiconfig';
import { instance } from './auth';
import { InHouseConsignment } from '../Types/delivery-app/InHouseConsignment';
import { EmailPdf } from '../Types/delivery-app/EmailPdf';

export const SendEmailToCarrier = async (requestData: { consignmentList: Consignment[]; recipientList: string[] }) => {
  return instance.post(`${apiUrl}/api/email/send`, JSON.stringify(requestData), { headers: { 'Content-Type': 'application/json' } });
};

export const SendTuusulaTransportEmailToCarrier = async (consignmentList: Consignment[]) => {
  return instance.post(`${apiUrl}/api/email/tuusula/send`, JSON.stringify(consignmentList), { headers: { 'Content-Type': 'application/json' } });
};

export const SendTransportRouteEmail = async (requestData: { consignmentList: Consignment[]; recipientList: string[]; directoCode: string; emailTemplateValue: string; totalPrice: number }) => {
  return instance.post(`${apiUrl}/api/email/transportRoute/send`, JSON.stringify(requestData), { headers: { 'Content-Type': 'application/json' } });
};

export const GetEmailMessagePreview = async (consignmentList: Consignment[]) => {
  return instance.post<string>(`${apiUrl}/api/email/preview`, JSON.stringify(consignmentList), { headers: { 'Content-Type': 'application/json' } });
};

export const GetTuusulaEmailMessagePreview = async (consignmentList: Consignment[]) => {
  return instance.post<string>(`${apiUrl}/api/email/tuusula/preview`, JSON.stringify(consignmentList), { headers: { 'Content-Type': 'application/json' } });
};
export const GetTransportRouteEmailPreview = async (consignmentList: Consignment[], directoCode: string, templateValue: string, totalPrice: number) => {
  return instance.post<string>(`${apiUrl}/api/email/transportRoute/preview?directoCode=${directoCode}&emailTemplateValue=${templateValue}&totalPrice=${totalPrice}`, JSON.stringify(consignmentList), { headers: { 'Content-Type': 'application/json' } });
};

/* export const SendPDFEmail = async (requestData: { base64: string; directoCode: string; deliveryData: InHouseConsignment; localTime: string; targetEmailAddress: string }) => {
  return instance.post(`${apiUrl}/api/email/send/pdf`, JSON.stringify(requestData), { headers: { 'Content-Type': 'application/json' } });
}; */

export const SendPDFEmail = async (requestData: EmailPdf) => {
  return instance.post(`${apiUrl}/api/email/send/pdf`, JSON.stringify(requestData), { headers: { 'Content-Type': 'application/json' } });
};

export const SavePdfDocument = async (requestData: { base64: string; directoCode: string }) => {
  return instance.post(`${apiUrl}/api/email/save/pdf`, JSON.stringify(requestData), { headers: { 'Content-Type': 'application/json' } });
};

import { useEffect, useRef, useState } from 'react';
import '../../../styles/tracking-app/end-delivery.css';
import LaunchIcon from '@mui/icons-material/Launch';
import { Button, IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ConsignmentStatus, InHouseConsignment } from '../../../Types/delivery-app/InHouseConsignment';
import { CompleteDelivery, GetInHouseConsignment, RemoveDeliveryFromLoadedConsignments, SendInHouseConsignmentToDirecto } from '../../../queries/deliveryDriverQueries';
import { AxiosResponse } from 'axios';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { GetPrintoutFromDirecto } from '../../../queries/queries';
import EtsNordLogo from '../../../assets/logo.png';
import SignatureCanvas from 'react-signature-canvas';
import CloseIcon from '@mui/icons-material/Close';
import Loading from '../../../components/common/Loading';
import { ReactComponent as NavigateBackIcon } from '../../../assets/tracking-app/navigate-back-icon.svg';
import { ReactComponent as CameraIconText } from '../../../assets/tracking-app/camera-icon-with-text.svg';
import { ReactComponent as DoneIcon } from '../../../assets/tracking-app/done-icon.svg';
import { ReactComponent as RoundArrowBackIcon } from '../../../assets/tracking-app/round-arrow-back.svg';
import { ReactComponent as EmailIcon } from '../../../assets/tracking-app/buttons/email-button.svg';
import useGeolocation from '../../../components/tracking-app/useGeolocation';
import { Navigate } from 'react-router-dom';
import { useCustomer } from '../../../components/login/user';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { SavePdfDocument, SendPDFEmail } from '../../../queries/email-queries';
import Modal from '../../../components/common/Modal';
import { EmailPdf } from '../../../Types/delivery-app/EmailPdf';

const EndDelivery = () => {
  const navigate = useNavigate();
  const { permissions } = useCustomer();
  const signatureCanvasRef = useRef<any>(null);
  const [deliveryData, setDeliveryData] = useState<InHouseConsignment>({} as InHouseConsignment);
  const { deliveryId } = useParams<{ deliveryId: string }>();
  const [signature, setSignature] = useState<string>(deliveryData.signatureBase64 ?? '');
  const [receiverName, setReceiverName] = useState<string>(deliveryData.packageReceiverName ?? '');
  const [pic, setPic] = useState<string>('');
  const [htmlDocument, setHtmlDocument] = useState<any>('');
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [images, setImages] = useState<string[]>([]);
  const [fullSizeImage, setFullSizeImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSendingData, setIsSendingData] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>("");
  const contentRef = useRef<any>(null);
  const signatureBoxWidth = window.innerWidth * 0.88;
  const { position, error } = useGeolocation();
  const userName = localStorage.getItem("user")
  const handleImageChange = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileReader = new FileReader();

      fileReader.onload = (e: any) => {
        const dataUrl = e.target.result; // This is a Data URL
        setImages((prevImages) => [...prevImages, dataUrl]);

        // Convert Data URL to raw Base64 string for the API
        const base64String = dataUrl.substring(dataUrl.indexOf(',') + 1);
        // Check if attachmentImage1Base64 or attachmentImage2Base64 is already set
        setDeliveryData((prevData) => {
          if (!prevData.attachmentImage1Base64 || prevData.attachmentImage1Base64 === '') {
            return { ...prevData, attachmentImage1Base64: base64String };
          } else {
            return { ...prevData, attachmentImage2Base64: base64String };
          }
        });
      };
      fileReader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleClearSignature = () => {
    setPic('');
    setSignature('');
    if (signatureCanvasRef.current === null) return;
    signatureCanvasRef.current.clear();
  };

  const handleSaveSignature = () => {
    if (signatureCanvasRef.current === null) return;
    const signatureImage = signatureCanvasRef.current.toDataURL();
    setSignature(signatureImage);
    setDeliveryData({ ...deliveryData, signatureBase64: signatureImage });
    return;
  };

  const pdfToBase64String = async (pdfBlob: Blob) => {
    return new Promise<string | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Check if reader.result is truthy and then split the result to get the base64 part
        const result = reader.result as string;
        const base64String = result ? result.split(',')[1] : null;
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(pdfBlob);
    });
  };

  const htmlStringToPdf = async (htmlString: string, downloadPdf: boolean = false) => {
    const iframeId = 'uniqueIframeId';
    let iframe = document.getElementById(iframeId) as HTMLIFrameElement;

    // If the iframe doesn't exist, create it and append it to the body
    if (!iframe) {
      iframe = document.createElement('iframe');
      iframe.id = iframeId; // Assign the unique ID to the iframe
      iframe.style.visibility = 'hidden';
      document.body.appendChild(iframe);
    }

    let iframedoc = iframe.contentDocument!;
    iframedoc.body.innerHTML = htmlString;

    // Capture the content of the iframe as a canvas
    let canvas = await html2canvas(iframedoc.body, {});
    // Convert the canvas to a JPEG image at reduced quality to decrease file size
    let imgData = canvas.toDataURL('image/jpeg', 1); // Adjust quality as needed

    // Create a PDF document and add the image as a page
    const doc = new JsPDF({
      format: 'a4',
      unit: 'mm'
    });
    // Add the JPEG image to the PDF, adjusting the format and dimensions as necessary
    doc.addImage(imgData, 'JPEG', 0, 0, 210, 297);

    // Get the file as a blob output
    let blob = doc.output('blob');

    // Optionally convert the PDF blob to a base64 string if needed
    var base64String = await pdfToBase64String(blob);

    if (downloadPdf) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `Saateleht ${deliveryData.directoCode}.pdf`;
      link.click();
      URL.revokeObjectURL(link.href);
    }
    if (base64String) {
      return base64String;
    } else {
      console.error('Error generating PDF base64 string');
    }
    // Clean up by removing the iframe from the document body
    document.body.removeChild(iframe);
  };

  const sendHtmlWithSignature = (originalHtml: string) => {
    const removeScriptsBeggining = originalHtml.slice(originalHtml.indexOf('<html xmlns:fo="http://www.w3.org/1999/XSL/Format"'));
    var removeScriptsAfter = removeScriptsBeggining.substring(0, removeScriptsBeggining.indexOf('</html>'));
    if (signature !== '' && signature !== null) {
      removeScriptsAfter = removeScriptsAfter.replace('<img sign="signhere" width="600" height="100" border="0" align="right">', `<img sign="signhere" width="600" height="100" border="0" align="right" src="${signature}" alt="Signature" />`);
    }
    if (receiverName !== '') {
      removeScriptsAfter = removeScriptsAfter.replace(
        '<td id="signature_name" class="b bottom2 td">',
        `<td id="signature_name" class="b bottom2 td">${receiverName}, ${new Date().toLocaleDateString('et-EE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })}`
      );
    }
    if (deliveryData.deliveryDriverName) {
      removeScriptsAfter = removeScriptsAfter.replace('<td id="driver_name" class="b bottom2 td">', `<td id="driver_name" class="b bottom2 td">${deliveryData.deliveryDriverName}`);
    }
    if (!deliveryData.deliveryDriverName) {
      removeScriptsAfter = removeScriptsAfter.replace('<td id="driver_name" class="b bottom2 td">', `<td id="driver_name" class="b bottom2 td">${userName}`);
    }

    const removePageNr = removeScriptsAfter.replace('Lk: {pdf:page_number}/{pdf:page_count}', '');
    const removeLogo = removePageNr.replace('<img border="0" height="70" src="/logos/files/ets_nord_9.jpg">', `<img border="0"  width="150" src="${EtsNordLogo}">`);
    const increasePaddingStart = removeLogo.replace('<body style="display:block; text-align:-webkit-center;">', '<body style="display:block; text-align:-webkit-center;"><div style="padding:50px; width:700px;">');
    const increasePaddingEnd = increasePaddingStart.replace('</body>', '</div></body>');
    return increasePaddingEnd;
  };

  const handleGetPrintout = async (directoCodeValue: string, downloadPdf: boolean = false) => {
    if (directoCodeValue === '' || directoCodeValue === undefined) return alert('Empty');
    var CMRPrintoutNumber = 1666;

    try {
      const response = await GetPrintoutFromDirecto(directoCodeValue, CMRPrintoutNumber);
      const documentWithSignature = sendHtmlWithSignature(response.data);
      setHtmlDocument(documentWithSignature);
      const base64String = await htmlStringToPdf(documentWithSignature, downloadPdf);
      return base64String;
    } catch (err) {
      console.log(err);
    }
  };

  const handleNameChange = (e: any) => {
    setReceiverName(e.target.value);
    setDeliveryData({ ...deliveryData, packageReceiverName: e.target.value });
  };

  const handleSaveToDirecto = async () => {
    if (!receiverName || !signature) {
      alert('Kauba vastuvõtja nimi peab olema täidetud');
      return;
    }
    setIsSendingData(true);
    const base64String = await handleGetPrintout(deliveryData.directoCode);
    if (base64String) {
      try {
        if (!position) {
          alert('Geolocation is not available. Please enable location services and try again.');
          /*  setIsLoading(false); */
          setIsSendingData(false)
          return;
        }
        const updatedDeliveryData = {
          ...deliveryData,
          attachmentBase64: base64String,
          consignmentStatus: ConsignmentStatus.Delivered,
          dropOffLatitude: position!.coords.latitude,
          dropOffLongitude: position!.coords.longitude
        };
        await SendInHouseConsignmentToDirecto(updatedDeliveryData);
        /* TODO: TEST THIS PROPERLY AFTER CHANGES*/
        await SavePdfDocument({ base64: base64String, directoCode: updatedDeliveryData.directoCode });
        await CompleteDelivery(deliveryData.id);
        navigate(`/carrier-driver/${deliveryData.deliveryDriverId}`);
        alert('Kinnitatud');

      } catch (error) {
        /*  setIsLoading(false); */
        setIsSendingData(false)
        console.error('Error sending to Directo:', error);
        alert('Failed to send data. Please try again.');
      }
    } else {
      console.error('attachmentBase64 is not set.');
      alert('Failed to generate delivery document. Please try again.');
    }
    /* setIsLoading(false); */
    setIsSendingData(false)
  };

  const handleEndDeliveryWithoutSignature = async () => {
    if (deliveryData.endDeliveryComment === null || deliveryData.endDeliveryComment === '') {
      alert('Kommentaar on kohustuslik');
      return;
    }
    if (signature) {
      alert('Allkirja väli on juba täidetud');
      return;
    }
    try {
      /* setIsLoading(true); */
      setIsSendingData(true);
      if (!position) {
        alert('Geolocation is not available. Please enable location services and try again.');
        /* setIsLoading(false); */
        setIsSendingData(false);
        return;
      }

      const updatedDeliveryData = {
        ...deliveryData,
        consignmentStatus: ConsignmentStatus.UnSigned,
        dropOffLatitude: position!.coords.latitude,
        dropOffLongitude: position!.coords.longitude
      };
      await SendInHouseConsignmentToDirecto(updatedDeliveryData);
      //changed to status finished 30.07.24
      await RemoveDeliveryFromLoadedConsignments(deliveryData.id);
      //await CompleteDelivery(deliveryData.id);
      /* setIsLoading(false); */
      setIsSendingData(false);
      navigate(`/carrier-driver/${deliveryData.deliveryDriverId}`);
      alert('Lõpetatud');
    } catch (error) {
      console.error('Error sending to Directo:', error);
      alert('Failed to send data. Please try again.');
      setIsSendingData(false);
    }

    //send to directo but dont send printout
    //modify status to 'Allkirjastamata'
    //põhjendus peab directosse jõudma
  };

  const handleGetDeliveryData = async () => {
    if (deliveryId !== undefined) {
      var tempDirectoCode = '';
      setIsLoading(true);
      await GetInHouseConsignment(+deliveryId)
        .then((response: AxiosResponse<InHouseConsignment>) => {
          setDeliveryData(response.data);
          tempDirectoCode = response.data.directoCode;
        })
        .catch((error) => {
          console.error(error);
        });
      await handleGetPrintout(tempDirectoCode);
      setIsLoading(false);
    }
  };

  const handleCameraClick = () => {
    const fileInput = document.getElementById('image-input');
    if (fileInput) {
      (fileInput as HTMLInputElement).click();
    }
  };

  /* TODO: TEST THIS PROPERLY AFTER CHANGES*/
  const handleSendEmail = async (emailAddress: string) => {

    if (deliveryId !== undefined && emailAddress) {
      let tempDirectoCode = '';
      setIsLoading(true);
      try {
        const response = await GetInHouseConsignment(+deliveryId);
        setDeliveryData(response.data);
        tempDirectoCode = response.data.directoCode;
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        setIsModalOpen(false)
        return; // Exit if there is an error
      }

      try {
        // Await the base64 string and check if it is valid
        const base64 = await handleGetPrintout(tempDirectoCode);

        if (typeof base64 === 'string') {
          // Create request data with the base64 string
          const requestData: EmailPdf = {
            base64: base64,
            deliveryData: deliveryData,
            directoCode: deliveryData.directoCode,
            targetEmailAddress: emailAddress,
            localTime: new Date().toLocaleString("et-EE")
          };

          // Send the PDF email with the base64 encoded data
          await SendPDFEmail(requestData);
          alert("Saateleht saadeti e-mailile")
        } else {
          console.error("handleGetPrintout did not return a valid base64 string.");
        }
      } catch (error) {
        console.error('Error generating PDF:', error);
      } finally {
        setIsLoading(false);
        setIsModalOpen(false)
      }
    }
    //setIsModalOpen(false)
  };

  useEffect(() => {
    handleGetDeliveryData();
  }, []);

  useEffect(() => {
    /* setIsButtonDisabled(!(receiverName?.trim() && signature?.trim())); */
    setIsButtonDisabled(!signature?.trim());
  }, [signature]);

  if (isLoading || isSendingData) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (!permissions.includes("PInHouseDriver")) {
    return <Navigate to="/unauthorized" />
  }

  return (
    <div className="app-view" style={{ marginTop: '25px' }}>

      <div className="navigation-bar">
        <div className="nav-item" >
          <IconButton onClick={() => navigate(-1)}>
            <NavigateBackIcon />
          </IconButton>
          <label htmlFor="back-button" style={{ color: '#fff' }}>
            Tagasi
          </label>
        </div>
        <div className="flex items-center justify-center header--title" style={{ width: '50%' }}>
          Vastuvõtmise akt
        </div>
        {/* <div className="nav-item" style={{ width: '25%' }}>
          <IconButton style={{ color: "#fff" }} title="Saada lähetus valitud e-mailile." onClick={() => setIsModalOpen(true)}>
            <AttachEmailIcon fontSize='large' />
          </IconButton>
          <label htmlFor="back-button" style={{ color: '#fff' }}>
            Saada emailile
          </label>
        </div> */}
        <div style={{ width: '25%', color: "#fff" }}>
          {error && <p>{error.message}</p>}
        </div>
      </div>
      <div className="content-area">
        <div className="end-delivery--picture-area">
          <div className="flex justify-center items-center gap-m">
            {images.map((image, index) => (
              <div key={index} className="flex justify-center items-center">
                <img src={image} alt={`Capture ${index + 1}`} style={{ width: '80%', height: '80%', maxHeight: '250px' }} onClick={() => setFullSizeImage(image)} />
                <IconButton onClick={() => handleRemoveImage(index)}>
                  <CloseIcon />
                </IconButton>
              </div>
            ))}
          </div>
          {images.length < 2 && (
            <IconButton onClick={handleCameraClick}>
              <CameraIconText />
              <input id="image-input" style={{ display: 'none' }} type="file" accept="image/*" capture="user" onChange={handleImageChange} />
            </IconButton>
          )}
        </div>

        <div className="end-delivery--info-area">
          <div className="flex justify-start items-center">
            <div className="content-item">Saateleht:</div>
            <div style={{ paddingLeft: '25px' }}>{deliveryData.directoCode}</div>
            <div>
              <IconButton onClick={() => handleGetPrintout(deliveryData.directoCode, true)}>
                <LaunchIcon />
              </IconButton>
            </div>
          </div>
        </div>

        <div ref={contentRef} className="end-delivery--printout-area">
          <div dangerouslySetInnerHTML={{ __html: htmlDocument }} />
        </div>
        <div style={{ width: '90%' }}>
          <div style={{ paddingLeft: '10px', paddingTop: '1rem', marginBottom: '1%' }}>Kommentaar</div>
          <textarea className="end-delivery--comment-area" name="endDeliveryComment" value={deliveryData.endDeliveryComment ?? ''} onChange={(e: any) => setDeliveryData({ ...deliveryData, endDeliveryComment: e.target.value })}></textarea>
        </div>
        <div className="content-area-signature">
          <div style={{ paddingLeft: '10px', paddingTop: '1rem', marginBottom: '1%' }}>Kauba vastuvõtja ees- ja perekonnanimi</div>
          <input className="input-addressForm" name="deliveryContactName" style={{ width: '100%', borderRadius: '10px', border: '2px solid #cbcbcb' }} placeholder="" value={receiverName || ''} onChange={(e: any) => handleNameChange(e)} />
          <div style={{ paddingLeft: '10px', paddingTop: '1rem' }}>Vasutvõtja allkiri</div>
          <div
            style={{
              marginTop: '1%',
              height: '225px',
              cursor: 'pointer',
              width: '100%',
              zIndex: '100'
            }}
          >
            {pic !== '' && pic !== null && pic !== undefined ? (
              <img src={`${pic}`} alt="Signature" />
            ) : (
              <div style={{ zIndex: '200', background: '#fff', border: '2px solid #cbcbcb', borderRadius: '10px' }}>
                <SignatureCanvas ref={signatureCanvasRef} canvasProps={{ height: 225, width: signatureBoxWidth }} onEnd={handleSaveSignature} />
              </div>
            )}
          </div>
          {fullSizeImage && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                zIndex: 1000,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img src={fullSizeImage} alt="Full size" style={{ maxHeight: '90vh', maxWidth: '90vw' }} onClick={() => setFullSizeImage(null)} />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  height: '30%'
                }}
              >
                <IconButton
                  className="close-icon"
                  style={{
                    background: 'none',
                    color: 'gray',
                    marginLeft: 'auto'
                  }}
                  onClick={() => setFullSizeImage(null)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <Modal show={isModalOpen} onClose={() => (setIsModalOpen(false))} >
          <form style={{ display: "flex", justifyContent: "center", flexDirection: "column" }} onSubmit={() => handleSendEmail(emailAddress)}>
            <div className="search-table-content__table-thead--td left" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
              <div style={{ marginBottom: "10px" }}>
                Sisesta e-maili aadress
              </div>
            </div>
            <div>
              <input className="input-addressForm" type="email" required={true} value={emailAddress} onChange={(e: any) => setEmailAddress(e.target.value)} />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button type='submit' style={{ background: "green", color: "#fff", marginTop: "10px", width: "100%" }}>Saada e-mail</Button>
            </div>
          </form>
        </Modal>
      </div>

      <div className="end-delivery--footer">
        <div className="footer-item">
          <IconButton onClick={handleSaveToDirecto} disabled={isButtonDisabled || isLoading || isSendingData}>
            <DoneIcon />
          </IconButton>
          <label htmlFor="done-button" style={{ color: '#fff' }}>
            Kinnita
          </label>
        </div>
        <div className="footer-item">
          <IconButton onClick={handleEndDeliveryWithoutSignature} disabled={!isButtonDisabled || isLoading || isSendingData}>
            <DoneIcon />
          </IconButton>
          <label htmlFor="done-button" style={{ color: '#fff' }}>
            Kinnita ilma allkirjata
          </label>
        </div>
        <div className="footer-item">
          <IconButton onClick={handleClearSignature}>
            <RoundArrowBackIcon />
          </IconButton>
          <label htmlFor="clear-button" style={{ color: '#fff' }}>
            Tühjenda
          </label>
        </div>
        <div className="footer-item" style={{ width: '25%' }}>
          <IconButton style={{ color: "#fff" }} title="Saada allkirjastatud saateleht e-mailile" onClick={() => setIsModalOpen(true)} disabled={isButtonDisabled}>
            <EmailIcon />
          </IconButton>
          <label htmlFor="back-button" style={{ color: '#fff' }}>
            Saada emailile
          </label>
        </div>
      </div>
    </div>
  );
};

export default EndDelivery;
